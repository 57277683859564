$typo: 'Glacial Indifference', 'Helvetica', sans-serif;

@font-face {
    font-family: "Glacial Indifference";
    src: url("fonts/GlacialIndifference-Regular.otf") format("opentype");
}
@font-face {
    font-family: "Glacial Indifference";
    font-weight: bold;
    src: url("fonts/GlacialIndifference-Bold.otf") format("opentype");
}
@font-face {
    font-family: "Glacial Indifference";
    font-style: italic;
    src: url("fonts/GlacialIndifference-Italic.otf") format("opentype");
}

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url("fonts/MaterialIcons.woff2") format('woff2');
}
.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

body {
    background-color: white;
    padding: 0;
    font-family: 'Helvetica', sans-serif;
}
.app {
    width: 100vw;
    min-height: 100vh;
    padding: 2rem;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    @media (display-mode: standalone) {
        padding-top: 0;
    }
}
header {
    display: flex;
    justify-content: space-between;
    color: gray;
}

button {
    border: none;
    background: none;
    padding: 0;
}

.albums {
    display: flex;
    justify-content: space-between;
}
.albums > div {
    flex: 1;
}
.albums img {
    max-width: 28vw;
}
.albums .album--details {
    margin-top: 1em;
}

.answer {
    display: flex;
}
.answer img {
    max-height: 80vmin;
    max-width: 80vmin
}
.answer--details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 2rem;
    font-family: $typo;
}
.answer--actions {
    margin-left: 2rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}
.answer--icon {
    font-size: 10vmin;
}

.good {
    color: #1bb31b;
}
.bad {
    color: red;
}

.album--details {
    font-family: $typo;
    font-size: 130%;

    .answer & {
        font-size: 160%;
        align-self: flex-start;
    }
}
.artist-name {
    font-weight: bold;
}
.album-name {
}
.track-name {
    font-style: italic;
    margin-bottom: 1em;
}

.progress {
    width: 100%;
    height: 5px;
    background-color: lightgray;
    overflow-x: hidden;
}
.progress--bar {
    width: 100%;
    height: 100%;
    background-color: black;
    transform: translateX(-100%);
}

.error--wrapper {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.error {
    background-color: darkred;
    color: white;
    padding: 1em;
    max-width: 90vw;
}